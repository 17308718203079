<template>
  <div>
    <div class="card border-0">
      <div
        class="card-body border-bottom border-4 border-light"
        v-if="chartData != null"
      >
        <overview-chart :id="'ujianPekta'" :dataSource="chartData" />
      </div>
      <div
        v-else
        class="card-body border-bottom border-4 border-light w-100 d-flex position-relative justify-content-center align-items-center"
        style="height: 394px"
      >
        <div class="text-center">
          <div class="spinner-grow text-muted" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="text-muted small mt-2">Sila tunggu. Memuatkan data...</p>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex">
            <input
              class="form-control me-2 input-custom"
              placeholder="Cari ..."
              v-model="seaching"
            />
            <div class="dropdown me-2">
              <button
                class="btn btn-sm border dropdown-toggle"
                type="button"
                id="dropdownMenuFilter"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Saring
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuFilter">
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseStateFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseStateFilter"
                    >Negeri</a
                  >
                  <div class="collapse" id="collapseStateFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.state">
                        <option :value="null" disabled selected hidden>
                          Pilih Negeri
                        </option>
                        <option
                          v-for="state in states"
                          :key="state"
                          :value="state"
                        >
                          {{ state }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseGenderFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseGenderFilter"
                    >Jantina</a
                  >
                  <div class="collapse" id="collapseGenderFilter">
                    <div class="p-2 mb-2">
                      <select class="form-select" v-model="filterOption.gender">
                        <option :value="null" disabled selected hidden>
                          Pilih Jantina
                        </option>
                        <option value="lelaki">Lelaki</option>
                        <option value="perempuan">Perempuan</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    class="dropdown-item text-reset"
                    data-bs-toggle="collapse"
                    href="#collapseAgeFilter"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAgeFilter"
                    >Umur</a
                  >
                  <div class="collapse" id="collapseAgeFilter">
                    <div class="d-flex p-2">
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Min umur"
                        type="number"
                        v-model="filterOption.age.min"
                      />
                      <input
                        class="form-control me-2 input-custom"
                        placeholder="Max umur"
                        type="number"
                        v-model="filterOption.age.max"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <button
              v-if="
                filterOption.state ||
                filterOption.gender ||
                filterOption.age.min ||
                filterOption.age.max
              "
              class="btn btn-sm border"
              type="button"
              @click="
                () => {
                  filterOption.state = null;
                  filterOption.gender = null;
                  filterOption.age.min = null;
                  filterOption.age.max = null;
                }
              "
            >
              Set semula
            </button>
          </div>
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-sm border me-2"
              @click="handleDownload"
              :disabled="findArray().length == 0"
            >
              Muat turun
            </button>
            <button
              type="button"
              class="btn btn-sm border"
              @click="handlePrint"
              :disabled="findArray().length == 0"
            >
              Cetak
            </button>
          </div>
        </div>
        <div class="tableFixHead">
          <div class="d-flex flex-row-reverse mt-3 mb-2 me-1">
            <div class="justify-content-center">
              <small class="text-muted"
                >Jumlah pengguna: {{ findArray().length }}</small
              >
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 30px">#</th>
                <th style="width: 350px">Nama</th>
                <th style="width: 350px">No. Kad Pengenalan</th>
                <th style="width: 100px">Umur</th>
                <th style="width: 100px">Jantina</th>
                <th style="width: 100px">Daerah</th>
                <th style="width: 100px">Negeri</th>
                <th style="width: 100px">Markah</th>
                <th style="width: 100px">Status</th>
              </tr>
            </thead>
            <tbody class="border-0">
              <tr
                class="border-light"
                v-for="(exam, i) in findArray()"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ exam.user_fullname }}</td>
                <td>{{ exam.id_no }}</td>
                <td>{{ exam.user_age }}</td>
                <td>{{ exam.user_gender }}</td>
                <td>{{ exam.district }}</td>
                <td>{{ exam.state }}</td>
                <td>{{ exam.course_mark ? exam.course_mark : 0 }}/100</td>
                <td>
                  <div
                      v-if="exam.course_mark < exam.course_pass_mark_percentage && exam.course_mark > 0"
                      class="badge rounded-pill bg-danger text-white"
                    >
                    Gagal
                  </div>
                  <div
                     v-if="exam.course_mark == 0  ||exam.course_mark == null "
                    class="badge rounded-pill bg-light text-muted"
                  >
                    Belum Mula
                  </div>
                  <div
                    v-if="exam.course_mark >= exam.course_pass_mark_percentage"
                    class="badge rounded-pill bg-success text-light"
                  >
                    Lulus
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../utils/API";
import overviewChart from "./../Chart/overviewChart.vue";
import * as XLSX from "xlsx";
import printJS from "print-js";

export default {
  name: "UjianPekta",
  components: {
    overviewChart,
  },
  data() {
    return {
      seaching: "",
      pektaApplications: [],
      chartData: null,
      users: [],
      states: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "Kuala Lumpur",
        "Labuan",
        "Sabah",
        "Putrajaya",
      ],
      filterOption: {
        state: null,
        gender: null,
        age: {
          min: null,
          max: null,
        },
      },
    };
  },
  watch: {
    // filterOption: {
    //   handler(_val){
    //     console.log('val :', _val)
    //   },
    //   deep: true
    // }
  },
  methods: {
    handleDownload() {
      let headers = ["#", "Nama","No. Kad Pengenalan", "Umur", "Jantina","Daerah","Negeri","Markah", "Status"];
      let rowData = this.findArray().map((user, index) => {
        const status =
          user.course_mark_status == "failed"
            ? "Gagal"
            : user.course_mark_status == null
            ? "Belum mula"
            : "Lulus";
        return [
        index + 1,
        user.user_fullname ? user.user_fullname.toUpperCase() : "-",
        user.id_no ? user.id_no.toUpperCase() : "-",
        user.user_age ? String(user.user_age).toUpperCase() : "-",
        user.user_gender ? user.user_gender.toUpperCase() : "-",
        user.district ? user.district.toUpperCase() : "-",
        user.state ? user.state.toUpperCase() : "-",
        user.course_mark ? user.course_mark : 0,
        status
        ];
      });
      let data = [headers, ...rowData];
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "sheetjs.xlsx");
    },
    handlePrint() {
      printJS({
        printable: this.findArray().map((user, index) => {
          const status =
          user.course_mark < user.course_pass_mark_percentage && user.course_mark > 0
            ? "Gagal"
            : user.course_mark == 0  ||user.course_mark == null 
            ? "Belum mula"
            : "Lulus";
          return {
            index: index + 1,
            nama: user.username ? user.username : "-",
            umur: user.age ? user.age : "-",
            jantina: user.gender ? user.gender : "-",
            markah: user.course_mark ? user.course_mark + "/100" : "0/100",
            status: status,
          };
        }),
        properties: [
          { field: "index", displayName: "#" },
          { field: "nama", displayName: "Nama" },
          { field: "umur", displayName: "Umur" },
          { field: "jantina", displayName: "Jantina" },
          { field: "markah", displayName: "Markah" },
          { field: "status", displayName: "Status" },
        ],
        type: "json",
      });
    },
    findArray() {
      // return this.seaching.length > 0
      //   ? this.users.filter((x) => {
      //       if (x.username != null)
      //         return x.username.toLowerCase().includes(this.seaching);
      //       else return null;
      //     })
      //   : this.users;

      let finalUsers = this.users;

      if (this.seaching.length > 0) {
        finalUsers = finalUsers.filter((x) => {
          if (x.full_name != null)
            return x.full_name.toLowerCase().includes(this.seaching.toLowerCase());
          else return null;
        });
      }

      if (this.filterOption.state) {
        finalUsers = finalUsers.filter((x) => {
          if (x.state != null)
            return x.state
              .toLowerCase()
              .includes(this.filterOption.state.toLowerCase());
        });
      }

      if (this.filterOption.gender) {
        finalUsers = finalUsers.filter((x) => {
          if (x.gender != null)
            return x.gender
              .toLowerCase()
              .includes(this.filterOption.gender.toLowerCase());
        });
      }

      if (this.filterOption.age.min && this.filterOption.age.max) {
        finalUsers = finalUsers.filter((x) => {
          if (x.age != null)
            return (
              x.age >= this.filterOption.age.min &&
              x.age <= this.filterOption.age.max
            );
        });
      }

      return finalUsers;
    },
    getPektaApplications() {
      API.get(
        `pekta-applications?populate[pekta][populate][0]=user&populate=pekta_application_assessments`
      )
        .then((response) => {
          const newData = response.data.data;
          let userData = [];
          let assessments = [];
          newData.forEach((element) => {
            if (
              element.attributes.pekta &&
              element.attributes.pekta.data != null &&
              element.attributes.pekta.data.attributes.user.data != null
            ) {
              element.attributes.pekta.data.attributes.user.data.attributes.full_name =
                element.attributes.pekta.data.attributes.fullname;
              userData.push(
                element.attributes.pekta.data.attributes.user.data.attributes
              );
            }
            if (
              element.attributes.pekta_application_assessments &&
              element.attributes.pekta_application_assessments.data.length > 0
            ) {
              assessments.push(
                ...element.attributes.pekta_application_assessments.data
              );
            }
          });
          this.users = userData;
          // let pdata20 = assessments.filter(
          //   (x) => x.attributes.question_correct_count < 20
          // );

          // let pdata40 = assessments.filter((x) => {
          //   if (
          //     x.attributes.question_correct_count > 20 &&
          //     x.attributes.question_correct_count < 40
          //   )
          //     return x;
          // });
          // let pdata80 = assessments.filter((x) => {
          //   if (
          //     x.attributes.question_correct_count > 40 &&
          //     x.attributes.question_correct_count < 60
          //   )
          //     return x;
          // });
          // let pdata100 = assessments.filter((x) => {
          //   if (
          //     x.attributes.question_correct_count > 60 &&
          //     x.attributes.question_correct_count < 100
          //   )
          //     return x;
          // });

          // const newItem = {
          //   labels: ["0-20", "20-40", "60-80", "80-100"],
          //   data: [
          //     pdata20.length,
          //     pdata40.length,
          //     pdata80.length,
          //     pdata100.length,
          //   ],
          // };
          // this.chartData = newItem;
        })
        .catch((err) => {});
    },
    async getGraphData(){
      const res01 = await API.post(`custom/fetch-graph-data`, { graph_type: "pekta_ujian_permohonan" })
      this.chartData = res01.data.result
    }
  },
  mounted() {
    this.getGraphData()
    this.getPektaApplications();
  },
};
</script>

<style scoped>
.body-container {
  min-height: calc(100vh - 170px);
}

.list-group-item {
  cursor: pointer;
}

.inactive {
  color: #9aa0ad;
}

.is-active {
  color: #4ca022;
}

.card-custom {
  position: relative;
  display: block;
  height: 40px;
}

.img-container {
  position: inherit;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/img/file-icon.svg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.col-3.col-custom {
  min-width: 250px !important;
}

.input-custom {
  width: 200px;
}

.tableFixHead {
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #f9fafb;
}

::-webkit-scrollbar {
  display: none !important;
}
</style>
