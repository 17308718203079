<template>
  <div>
    <div class="card-body border-bottom border-4 border-light">
      <h5>Template</h5>
      <div class="row g-3 mt-3">
        <div class="col-2" v-for="(item, index) in templates" :key="index">
          <div class="card card-custom mb-1 border-0 overflow-hidden shadow-0">
            <div class="card-body h-100 w-100 p-0">
              <div class="img-container"></div>
            </div>
          </div>
          <div class="text-center">
            <h6 class="mb-0 small fw-normal">
              {{ item.attributes.name }}
            </h6>
            <a
              :href="`${item.attributes.file.data.attributes.url}`"
              target="_blank"
              class="btn small text-muted"
              >Muat Turun</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      <!-- <h5 class="mb-3">Senarai Laporan Manual</h5> -->
      <div class="d-flex justify-content-end align-items-center mb-2" v-if="currentUser && currentUser.admin_type == 'admin_state'">
        <!-- <input
          class="form-control me-2 input-custom"
          placeholder="Search ..."
        /> -->
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-sm border me-2 align-middle"
            data-bs-toggle="modal"
            data-bs-target="#addModal"
            @click="modalOpen"
          >
            Tambah<i class="ri-add-line align-middle ms-2"></i>
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-start align-items-center mb-3">
        <div>
          <select class="form-select" v-model="selectedCategory">
              <option :value="null" disabled selected hidden>Pilih kategori</option>
              <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
            </select>
        </div>
        <div class="ms-3">
          <date-picker v-model="yearSelection" type="year" placeholder="Pilih tahun" />
        </div>
      </div>

      <table v-if="selectedCategory && loadReports().length > 0" class="table">
        <thead>
          <tr>
            <th>#</th>
            <th class="text-align-center">Tajuk</th>
            <th class="text-align-center">Kategori</th>
            <th class="text-align-center">Negeri</th>
            <th class="text-align-center">Tahun</th>
            <th class="text-align-center">Fail</th>
            <th class="text-align-center"></th>
          </tr>
        </thead>
        <tbody class="border-0">
          <tr v-for="(report, i) in loadReports()" :key="report.id" class="border-light">
            <td>{{ i + 1 }}</td>
            <td>
              {{ report.attributes.name }}
            </td>
            <td class="text-align-center">
              {{ report.attributes.tags }}
            </td>
            <td class="text-align-center">
              {{ report.attributes.state? report.attributes.state : '-' }}
            </td>
            <td class="text-align-center">
              {{ formatDateYear(report.attributes.report_year) }}
            </td>
            <td class="text-align-center">
              <span v-if="report.attributes.file && report.attributes.file.data && report.attributes.file.data.length > 0">
                <a v-for="item in report.attributes.file.data" :key="item.id"
                  :href="item.attributes.url.startsWith('http') ? item.attributes.url : `https://${item.attributes.url}`"
                  target="_blank"
                >
                  <img
                    style="height: 28px"
                    src="../../../assets/img/download-file.png"
                    alt=""
                  />
                </a>
              </span>
              <!-- <a v-if="report.attributes.file.data"
                :href="`${report.attributes.file.data.attributes.url}`"
                target="_blank"
                ><img
                  style="height: 28px"
                  src="../../../assets/img/download-file.png"
                  alt=""
              /></a> -->
            </td>
            <td>
              <button @click="deleteReport(report)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <!-- <tr></tr> -->
        </tbody>
      </table>

      <div v-else-if="loadReports().length == 0">
        Tiada laporan.
      </div>
    
    </div>
    <!--- Modal -->

    <div class="modal" tabindex="-1" id="addModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Muat Naik Laporan</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="w-100 d-flex justify-content-center" v-if="requireMsg">
              <p class="text-danger">Semua Perlu di isi</p>
            </div>

            <div class="mb-3">
              <label for="formFileReport" class="form-label"
                >Cari fail untuk muat naik</label
              >
              <input
                class="form-control form-control-sm"
                @change="onFileChange"
                id="formFileReport"
                type="file"
                placeholder="Cari fail untuk muat naik"
                multiple
              />
            </div>
            <div class="mb-3">
              <label for="formFileTitle" class="form-label">Tajuk Fail</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="formFileTitle"
                v-model="newReport.name"
                placeholder="Tajuk Fail"
              />
            </div>
            <div class="mb-3">
              <label for="forFileCategory" class="form-label">Negeri</label>
                <select
                  class="form-select btn-sm"
                  aria-label="Default select example"
                  v-model="newReport.state"
                >
                  <option disabled :value="null">Pilih Negeri</option>
                  <option
                    v-for="(state, index) in states"
                    :key="index"
                    :value="state"
                  >
                    {{ state }}
                  </option>
                </select>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="formFileYear" class="form-label">Untuk Tahun</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="formFileYear"
                  min="2010"
                  max="2099"
                  v-model="newReport.report_year"
                  placeholder="Tahun"
                />
              </div>

              <div class="col">
                <label for="forFileCategory" class="form-label">Kategori</label>
                <select
                  class="form-select btn-sm"
                  aria-label="Default select example"
                  v-model="newReport.tags"
                >
                  <option disabled :value="null">Pilih Kategori</option>
                  <option
                    v-for="(category, index) in categories"
                    :key="index"
                    :value="category"
                  >
                    {{ category }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary w-auto btn-sm"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              type="button"
              class="btn btn-primary w-auto btn-sm"
              @click="submitFormFile"
            >
              Hantar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import API from "../../../utils/API";
import $ from "jquery";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "ManualReport",
  components: { DatePicker },
  data() {
    return {
      templates: [],
      categories: ["KAH", "KIH", "KPH", "KKH", "HISHAJ"],
      selectedCategory: null,
      states: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "WP Kuala Lumpur",
        // "Labuan",
        "Sabah",
        // "Putrajaya"
      ],
      reports: [],
      newReport: {
        files: [],
        name: null,
        state: null,
        report_year: "2022",
        tags: null,
      },
      requireMsg: false,
      yearSelection: moment().year()
    };
  },
  setup() {
    console.log("setup");
  },
  watch: {
    newReport: {
      handler(val) {
        // do stuff
        console.log(val);
      },
      deep: true,
    },
    yearSelection(){
      this.getManualReport()
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    formatDateYear(date) {
      return moment(date).format("YYYY");
    },
    modalOpen() {
      console.log($("#formFileReport"));
      Object.entries(this.newReport).forEach((element) => {
        if (element[0] == "report_year") {
          this.newReport[element[0]] = 2022;
        } else {
          this.newReport[element[0]] = null;
        }
      });
      $("#formFileReport").val(null);

      console.log(this.newReport);
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.newReport.files = files;
      //   this.createImage(files[0]);
    },
    submitFormFile() {
      const emptyField = Object.entries(this.newReport).find(([key, value]) => {
        console.log(value);
        return value == null || value == "";
      });
      console.log(emptyField);
      if (!emptyField) {
        const filesToUpload = this.newReport.files;
        let year = this.newReport.report_year;
        this.newReport.report_year = new Date(`01-02-${year}`);
        this.newReport.users = this.currentUser.id
        delete this.newReport.files;
        const formData = new FormData();
        Array.from(filesToUpload).forEach(fileItem => formData.append(`files.file`, fileItem, fileItem.name))
        formData.append("data", JSON.stringify(this.newReport));
        console.log('formData : ', formData);

        API.post("report-submisions", formData)
          .then((res) => {
            delete this.newReport.users

            console.log(res);
            $(`#addModal`).toggle();
            $(".modal-backdrop").remove();
            this.getManualReport();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.requireMsg = true;
      }
    },
    async getManualReport() {
      let url = `report-submisions?populate=%2A&sort[0]=tags&pagination[pageSize]=200`
      if(this.currentUser.admin_type == 'admin_state'){
        url += `&filters[users]=${this.currentUser.id}`
      }

      let selectedYear = moment(this.yearSelection).year()
      let startDate = moment([selectedYear]).startOf('year').format('YYYY-MM-DD')
      let endDate = moment([selectedYear]).endOf('year').format('YYYY-MM-DD')
      url += `&filters[$and][0][report_year][$lte]=${endDate}&filters[$and][1][report_year][$gte]=${startDate}`
      
      const resReport = (await API.get(url)).data;
      console.log(resReport);
      this.reports = resReport.data;
    },
    loadReports() {
      let reports = this.reports

      if(this.selectedCategory){
        reports = reports.filter((x) => {
          return x.attributes.tags == this.selectedCategory
        })
      }

      return reports
    },
    async deleteReport(report) {
      const res = await API.delete("report-submisions/" + report.id);
      if (res.toDelete) await this.getManualReport();
    },
  },
  async mounted() {
    console.log("mounted");
    const resTemplate = (await API.get(`report-templates?populate=%2A`)).data;
    this.templates = resTemplate.data;
    this.getManualReport();
  },
};
</script>

<style scoped>
.body-container {
  min-height: calc(100vh - 170px);
}

.list-group-item {
  cursor: pointer;
}

.inactive {
  color: #9aa0ad;
}

.is-active {
  color: #4ca022;
}

.card-custom {
  position: relative;
  display: block;
  height: 40px;
}

.img-container {
  position: inherit;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/img/file-icon.svg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.col-3.col-custom {
  min-width: 250px !important;
}

.input-custom {
  width: 200px;
}
</style>